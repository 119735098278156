<template>
  <TransitionRoot
    appear
    :show="open"
    as="template"
  >
    <Dialog
      as="div"
      @close="open = false"
    >
      <TransitionChild
        as="template"
        enter-from="opacity-0 translate-x-full"
        enter-to="opacity-100"
        leave-from="opacity-100"
        leave-to="opacity-0 translate-x-full"
      >
        <div
          class="fixed right-0 top-0 z-40 flex h-screen w-full max-w-md flex-col border-l border-gray-900 bg-white !pb-0 transition-all duration-500 max-sm:max-w-[calc(100%-5rem)]"
        >
          <DialogPanel class="flex h-full flex-col max-md:pt-16">
            <DialogTitle class="px-6 pt-10 text-lg font-semibold xl:px-10">
              {{ title }}
            </DialogTitle>
            <UiButton
              class="absolute right-4 top-24 md:top-4"
              icon="XMark"
              type="link"
              @click="open = false"
            />

            <div class="grow overflow-y-auto">
              <FormKit
                type="form"
                :actions="false"
                form-class="h-full flex flex-col"
                @submit="onSubmit"
                @node="emit('node', $event)"
              >
                <div class="grow p-6 xl:p-10">
                  <slot />
                </div>
                <div
                  class="sticky bottom-0 flex items-center justify-end gap-4 border-t border-gray-900 bg-white px-5 py-6"
                >
                  <UiButton
                    type="outline"
                    :label="cancelButtonText"
                    @click="open = false"
                  />
                  <slot name="controls" />
                </div>
              </FormKit>
            </div>
          </DialogPanel>
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import type {FormKitNode} from '@formkit/core';

withDefaults(defineProps<{
  title: string
  cancelButtonText?: string
  onSubmit?: (data: Record<string, unknown>, node: FormKitNode) => Promise<unknown>
}>(), {
  cancelButtonText: 'Sluiten',
  onSubmit: undefined,
});

const open = defineModel<boolean>('open', {
  required: true,
});

const emit = defineEmits<{
  (event: 'node', node: FormKitNode): void
}>();
</script>
